import React, { useEffect, useState, useContext } from "react";
import BASE_URL from "../constant";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import htmlToPlainText from "../htmltotext";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { t } from "i18next";

const Checkout = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState("0.00");
  const [product_cart, setProductCart] = useState([]);
  const { cart, addToCart, removeFromCart, currentUser, getUserData } =
    useContext(AuthContext);
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  const [symbol, setSymbol] = useState("$");
  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "he" ? "rtl" : "ltr"
  );

  useEffect(() => {
    const svLangdir = localStorage.getItem("language");
    const svLang = localStorage.getItem("currency");
    setDirection(svLangdir === "he" ? "rtl" : "ltr");
    setSaveLang(svLang);
    //  console.log(svLangdir);
    setSymbol(svLang !== "ils" ? "$" : "₪");
    fetchCart();
  }, []);
  const fetchCart = async () => {
    try {
      const userData = getUserData();
      const response = await axios.get(
        `${BASE_URL}/api_cart.php?action=detailed_cart&user_id=${userData.id}&currency=${savedLang}`
      );
      const bookData = response.data;
      //console.log(response.data);

      setProductCart(bookData.cart);
      setProducts(bookData.cart.details);

      const totalSum = bookData.cart.details.reduce((total, item) => {
        const itemPrice = item.warehouse_quantity > 0 ? item.price : 0; // Choose the price based on language
        return total + item.quantity * parseFloat(itemPrice); // Multiply quantity by price and add to total
      }, 0);
      setTotal(parseFloat(totalSum).toFixed(2));
      //  setTotal(bookData.cart.final_amount);
    } catch (error) {
      setProducts([]);

      setTotal("0.00");
      console.error("Error fetching books:", error);
    }
  };
  const purchase = () => {
    if (total > 0) {
      // e.preventDefault();
      console.log(product_cart);
      if (currentUser) {
        navigate("/pages/Stripe", {
          state: {
            product: product_cart,
            amount: total,
            type: "cart",
            currency: savedLang,
          },
        });
      } else {
        toast.error(`Your need to login first`);
      }
    } else {
      toast.error(`The total amount can not be processed`);
    }
    // addBundleToCart(pro);
  };
  return (
    <div className="bg-gray-100 h-screen py-8">
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-semibold mb-4">{t("summry")}</h1>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:w-3/4">
          <div className="bg-white rounded-lg shadow-md p-6 mb-4">
  <div className="overflow-x-auto max-h-96">
    <table className="w-full">
      <thead>
        <tr>
          <th
            className={`font-semibold ${
              direction === "rtl" ? "text-right px-2" : "text-left px-4"
            } `}
          >
            {t("product")}
          </th>
          <th
            className={`font-semibold ${
              direction === "rtl" ? "text-right" : "text-left"
            } px-4`}
          >
            {t("price")}
          </th>
          <th
            className={`font-semibold ${
              direction === "rtl" ? "text-right" : "text-left"
            } px-4`}
          >
            {t("quantity")}
          </th>
          <th
            className={`font-semibold ${
              direction === "rtl" ? "text-right" : "text-left"
            } px-4`}
          >
            {t("total")}
          </th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <tr key={product.id}>
            <td className="py-4">
              <div className="flex items-center">
                <img
                  className="h-16 w-16 mr-4"
                  src={product.images[0]}
                  alt="Product image"
                />
                <span className="font-semibold">
                  {htmlToPlainText(product.title)}
                </span>
              </div>
            </td>
            <td>
              <p
                className={`ml-4 ${
                  product.warehouse_quantity > 0 ? "" : "text-red-500"
                } text-sm`}
              >
                {product.warehouse_quantity > 0
                  ? `${symbol} ${product.price}`
                  : t("outofstock")}
              </p>
            </td>
            <td className="py-4">
              <div className="flex items-center">
                <span className="text-center w-8">
                  {product.quantity}
                </span>
              </div>
            </td>
            <td>
              <p
                className={`ml-4 ${
                  product.warehouse_quantity > 0 ? "" : "text-red-500"
                } text-sm`}
              >
                {product.warehouse_quantity > 0
                  ? `${symbol} ${(product.quantity * product.price).toFixed(2)}`
                  : t("outofstock")}
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

          </div>
          <div className="md:w-1/4">
            <div className="bg-white rounded-lg shadow-md p-6">
              {/* <h2 className="text-lg font-semibold mb-4">Summary</h2> */}
              <div className="flex justify-between mb-2">
                <span>{t("subtotal")}</span>
                <span>
                  {symbol} {total}
                </span>
              </div>

              <hr className="my-2" />
              <div className="flex justify-between mb-2">
                <span className="font-semibold">{t("total")}</span>
                <span className="font-semibold">
                  {symbol} {total}
                </span>
              </div>
              <button
                className="bg-[#3b200f] text-white py-2 px-4 rounded-lg mt-4 w-full hover:bg-red-600"
                onClick={purchase}
              >
                {t("pay_now")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
