import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import BASE_URL from "../constant";
import { FaOpencart, FaPrint, FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import htmlToPlainText from "../htmltotext";

const ImageModal = ({ isOpen, onClose, imageSrc }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 rounded">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-red-600"
        >
          X
        </button>
        <img src={imageSrc} alt="Preview" className="max-w-full max-h-screen" />
      </div>
    </div>
  );
};

const MyPrintables = () => {
  const { t } = useTranslation();
  const [invoices, setInvoice] = useState([]);
  const { getUserData } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState("");

  useEffect(() => {
    const userdata = getUserData();
    GetPrints(userdata);
  }, [getUserData]);

  const GetPrints = async (orderData) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api_print.php?user_id=${orderData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Prints successfully fetched:", response.data);
      if (Array.isArray(response.data)) {
        setInvoice(response.data);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const printPage = (imageSrc, title) => {
    const printWindow = window.open("", "", "height=600,width=800");

    // Create the HTML structure with inline CSS to control the print layout
    const printContent = `
        <html>
          <head>
            <title>${title}</title>
            <style>
              @media print {
                @page {
                  size: auto;
                  margin: 0;
                }
                html, body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                canvas {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            </style>
          </head>
          <body>
            <canvas id="printCanvas"></canvas>
            <script>
              const canvas = document.getElementById('printCanvas');
              const ctx = canvas.getContext('2d');
              const img = new Image();
              img.src = '${imageSrc}';
              img.onload = function() {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                window.print();
                window.close();
              };
            </script>
          </body>
        </html>
      `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
  };

  const viewPage = (imageSrc) => {
    setCurrentImageSrc(imageSrc); // Set the current image source for the modal
    setModalOpen(true); // Open the modal to view the image
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-8 py-[40px] px-20">
        {invoices.length > 0 ? (
          invoices.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 border rounded-lg shadow"
            >
              <div className="relative mb-4 w-full h-100 rounded">
                {item.page_path ? (
                  <img
                    src={`${BASE_URL}/${item.page_path}`}
                    alt={htmlToPlainText(item.title)}
                    className="w-full h-full object-cover rounded"
                  />
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <svg
                      fill="#000000"
                      width="800px"
                      height="800px"
                      viewBox="0 0 32 32"
                      className="h-full w-full object-cover rounded"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <style>{`.cls-1{fill:none;}`}</style>
                      </defs>
                      <title>no-image</title>
                      <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
                      <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
                      <rect
                        id="_Transparent_Rectangle_"
                        data-name="&lt;Transparent Rectangle&gt;"
                        className="cls-1"
                        width="32"
                        height="32"
                      />
                    </svg>
                    <span className="absolute text-white bg-black bg-opacity-50 p-2 rounded">
                      {t("alert")}
                    </span>
                  </div>
                )}
              </div>
              <h3 className="text-lg font-semibold">
                {htmlToPlainText(item.title)}
              </h3>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <button
                  className="p-2 bg-red-500 rounded-full text-white"
                  onClick={() => {
                    if (item.page_path) {
                      viewPage(`${BASE_URL}/${item.page_path}`);
                    } else {
                      alert(t("alert1"));
                    }
                  }}
                >
                  <FaSearch />
                </button>
                <button
                  className="p-2 bg-[#fde5c3c4] rounded-full text-black"
                  onClick={() => {
                    if (item.page_path) {
                      printPage(`${BASE_URL}/${item.page_path}`,item.title);
                    } else {
                      alert(t("alert1"));
                    }
                  }} // Fix: Pass the function correctly
                >
                  <FaPrint />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p> {t('noitemfound')}</p>
        )}
      </div>

      {/* Modal for viewing images */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        imageSrc={currentImageSrc}
      />
    </>
  );
};

export default MyPrintables;
