import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import {
  FaShoppingCart,
  FaBookReader,
  FaHeart,
  FaHeartBroken,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../constant";
import Slider from "react-slick";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { AuthContext } from "../context/AuthContext";

import {
  RiCheckboxBlankCircleFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import htmlToPlainText from "../htmltotext";
import { BiCart } from "react-icons/bi";

const Product = () => {
  const [isAlerttabOpen, setAlertItabOpen] = useState(false);
  const swiperRefswe = useRef(null);
  const { id } = useParams();
  const [showRentForm, setShowRentForm] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("");
  const serviceId = parseInt(id, 10);
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const {
    cart,
    addToCart,
    removeFromCart,
    currentUser,
    getUserData,
    fetchUserCart,
  } = useContext(AuthContext);
  // const userData = getUserData();
  const [isFavorite, setIsFavorite] = useState(false); // State to track if the book is favorited
  const [favoriteid, setFavorite] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [size, setSize] = useState([]);
  const [price, setPrice] = useState(0);
  const { t, i18n } = useTranslation();
  const [cart_quantity, setCartQuantity] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api_book.php/books/${serviceId}`)
      .then((res) => {
        setProduct(res.data);
        setMaterials(
          res.data.material?.map((item) => ({
            id: item.index,
            name: item,
            selected: false,
          })) || []
        );
        setSize(
          res.data.size?.map((item) => ({
            id: item.index,
            name: item,
            selected: false,
          })) || []
        );
        checkIfFavorite();
      })
      .catch((err) => console.log(err));
  }, [serviceId]);
  useEffect(() => {
    if (cart && cart.details.length > 0) {
      setCartQuantity(cart.details);
    }
  }, [cart]);
  // const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem("currency") || "usd");
  const [savedLang, setSaveLang] = useState(
    localStorage.getItem("currency") || "usd"
  );
  const [symbol, setSymbol] = useState("$");
  useEffect(() => {
    const svLang = localStorage.getItem("currency");
    setSaveLang(svLang);
    console.log(savedLang);
    setSymbol(svLang !== "ils" ? "$" : "₪");
    if (items.length == 0) {
      setPrice(svLang !== "ils" ? product.price : product.price_is);
    }
  });
  useEffect(() => {
    if (!serviceId) return; // Ensure serviceId is defined

    axios
      .get(`${BASE_URL}/api_volumes.php?book_id=${serviceId}`)
      .then((res) => {
        const slideData = res.data.map((book) => ({
          ...book,
          quantity: 0,
          selected: false,
        }));
        // console.log(slideData);

        // setItems(slideData);
        let total = 0;

        const updatedItems = slideData.map((item) => {
          const warehouse_quantity =
            savedLang !== "ils"
              ? item.warehouse_quantity
              : item.warehouse_quantity_is;

          if (
            parseInt(item.must_buy) === 1 &&
            parseInt(warehouse_quantity) > 0
          ) {
            total +=
              savedLang !== "ils"
                ? parseFloat(item.price)
                : parseFloat(item.price_is);
            return {
              ...item,
              selected: true,
              quantity: 1,
            };
          }

          return item;
        });

        setItems(updatedItems);
        setPrice(total.toFixed(2));

        // }
      })
      .catch((err) => {
        setPrice(savedLang !== "ils" ? product.price : product.price_is);
        //console.error("Error fetching data:", err);
      });
  }, [serviceId, product]); // Ensure product is also included if used

  const [modalOpen, setModalOpen] = useState(false);
  const [passwordmodalOpen, setpasswordModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openpassModal = () => {
    setpasswordModalOpen(true);
  };

  const closepassModal = () => {
    setpasswordModalOpen(false);
  };
  // Function to check if the current book is favorited by the user
  const checkIfFavorite = () => {
    const userData = getUserData();

    axios
      .get(
        `${BASE_URL}/api_book.php/favoritebooks?user_id=${userData.id}&book_id=${id}`
      )
      .then((res) => {
        console.log(res.data);

        setIsFavorite(res.data.length > 0);
      })
      .catch((err) => console.log(err));
  };

  

  const handleToggleFavorite = async () => {
    const userData = getUserData();
    if (isFavorite) {
      // Remove from favorites logic
      console.log(favoriteid);
      try {
        const response = await axios.delete(
          `${BASE_URL}/api_book.php/favoritebooks/${favoriteid}`
        );

        if (response.status === 200) {
          alert("Book removed from favorites!");
          setIsFavorite(false); // Update UI to reflect removal
        } else {
          alert("Failed to remove book from favorites");
        }
      } catch (error) {
        alert("Failed to remove book from favorites. Please try again later.");
        console.error("Error removing book from favorites:", error);
      }
    } else {
      // Add to favorites logic
      try {
        const formData = new FormData();

        formData.append("user_id", userData.id);
        formData.append("book_id", id);

        // Configure the Axios request
        const config = {
          method: "post",
          url: `${BASE_URL}/api_book.php/favoritebooks`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        // Make the Axios request
        axios(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            //setNewProfileImage(null);
            alert("Book added to favorites!");
            setIsFavorite(true); // Update UI to reflect addition
          })
          .catch((error) => {
            alert("Failed to add book to favorites");
            console.log(error);
          });
      } catch (error) {
        alert("Failed to add book to favorites. Please try again later.");
        console.error("Error adding book to favorites:", error);
      }
    }
  };

  const handleRentClick = () => {
    setShowRentForm(true);
  };
  const purchase = async (e, pro) => {
    e.preventDefault();

    if (!currentUser) {
      toast.error("You need to log in first");
      return;
    }

    if (items && items.length > 0) {
      // Check if any items are selected
      const selectedItems = items.filter((item) => item.selected);

      if (selectedItems.length > 0) {
        const data = {
          items_id: selectedItems.map((item) => item.id),
          items_quantity: selectedItems.map((item) => item.quantity),
          product: pro,
          price: price,
        };

        try {
          // Call addToCart with the selected data and refresh on success
          await addToCart(data);
          setAlertItabOpen(true);
          // fetchUserCart(currentUser.id);
          // window.location.reload(); // Refresh page after adding to cart
        } catch (error) {
          console.error("Failed to add to cart:", error);
          alert("An error occurred. Please try again.");
        }
      } else {
        // No items selected, show an alert or handle accordingly
        alert("Please select at least one product before adding to cart.");
      }
    } else {
      // Single product scenario without multiple items
      const availableQuantity =
        savedLang !== "ils"
          ? pro.warehouse_quantity
          : pro.warehouse_quantity_is;

      if (availableQuantity > 0 && availableQuantity >= quantity) {
        const data = {
          product: pro,
          quantity: quantity,
          price: price,
        };

        try {
          await addToCart(data);
          setAlertItabOpen(true);
          // fetchUserCart(currentUser.id);
          // window.location.reload(); // Refresh page after adding to cart
        } catch (error) {
          console.error("Failed to add to cart:", error);
          alert("An error occurred. Please try again.");
        }
      } else {
        alert(`${t("book")} ${htmlToPlainText(pro.title)} ${t("not_enough")}`);
      }
    }
  };

  // const purchase = async (e, pro) => {
  //   e.preventDefault();

  //   if (currentUser) {
  //     if (items && items.length > 0) {
  //       // Check if any items are selected
  //       const selectedItems = items.filter((item) => item.selected);

  //       if (selectedItems.length > 0) {
  //         const data = {
  //           items_id: selectedItems.map((item) => item.id),
  //           items_quantity: selectedItems.map((item) => item.quantity),
  //           product: pro,
  //           price: price,
  //         };

  //         // Call addToCart with the selected data
  //         addToCart(data);
  //       } else {
  //         // No items selected, show an alert or handle accordingly
  //         alert("Please select at least one product before adding to cart.");
  //       }
  //     } else {
  //       const availablequantity =
  //         savedLang != "ils"
  //           ? pro.warehouse_quantity
  //           : pro.warehouse_quantity_is;
  //       if (availablequantity > 0 && availablequantity >= quantity) {
  //         const data = {
  //           product: pro,
  //           quantity: quantity,
  //           price: price,
  //         };
  //         addToCart(data);
  //       } else {
  //         alert(
  //           `${t("book")} ${htmlToPlainText(pro.title)} ${t("not_enough")}`
  //         );
  //       }
  //     }
  //     // addToCart(pro);
  //   } else {
  //     toast.error(`Your need to login first`);
  //   } //  navigate('/pages/Stripe',{state:{
  // };
  useEffect(() => {
    console.log(BASE_URL);
    console.log(id);
    axios
      .get(`${BASE_URL}/api_book.php/books/${serviceId}`)
      .then((res) => {
        console.log(res.data);
        setProduct(res.data); // Assuming the response has a services array
      })
      .catch((err) => console.log(err));
  }, [serviceId]);

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // vol
  const [items, setItems] = useState([]);
  const handleCheckboxChange = (itemId) => {
    const item = items.find((item) => item.id === itemId);
    const warehouse_quantity = item
      ? savedLang !== "ils"
        ? item.warehouse_quantity
        : item.warehouse_quantity_is
      : 0;

    // Check if warehouse quantity is greater than 0
    if (warehouse_quantity > 0) {
      setItems(
        items.map((item) => {
          if (item.id === itemId) {
            const updatedSelected = !item.selected;
            handleitemQuantityChange(itemId, updatedSelected ? 1 : 0);
            return {
              ...item,
              selected: updatedSelected,
              quantity: updatedSelected ? 1 : 0,
            };
          }
          return item;
        })
      );
    } else {
      // Alert when the quantity is not available
      alert(
        `${t("book")} ${items.find((item) => item.id === itemId)?.title} ${t(
          "not_enough"
        )}`
      );
    }
  };

  const handleitemQuantityChange = (itemId, newQuantity) => {
    // Get the warehouse quantity based on the language
    const item = items.find((item) => item.id === itemId);
    const warehouse_quantity = item
      ? savedLang !== "ils"
        ? item.warehouse_quantity
        : item.warehouse_quantity_is
      : 0;

    // Check if warehouse quantity is greater than 0
    if (warehouse_quantity > 0 && warehouse_quantity >= newQuantity) {
      // Update the item quantity in the list
      const updatedItems = items.map((item) =>
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      );

      // Recalculate the total price based on the updated quantities
      const newPrice = updatedItems.reduce((total, item) => {
        return (
          total +
          item.quantity *
            parseFloat(savedLang !== "ils" ? item.price : item.price_is)
        );
      }, 0.0);

      // Set the updated items and price
      console.log(newPrice.toFixed(2));
      setItems(updatedItems);
      setPrice(newPrice.toFixed(2));
    } else {
      // Alert when the quantity is not available
      alert(
        `${t("book")} ${items.find((item) => item.id === itemId)?.title} ${t(
          "not_enough"
        )}`
      );
    }
  };

  const handleMaterialCheckboxChange = (itemname) => {
    setMaterials(
      materials.map((item) => ({
        ...item,
        selected: item.name === itemname ? !item.selected : item.selected,
      }))
    );
  };
  const history = useNavigate();
  const [code, setCode] = useState("");
  const handleChange = (index, value) => {
    // Ensure the value is a single digit (0-9)
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
    }
  };

  const checkCode = () => {
    const enteredCode = code.join("");
    // Example condition: Check if code is valid (e.g., length is 4 and matches a specific code)
    if (enteredCode.length === 4 && enteredCode === "2134") {
      // Navigate to the PDF viewer page
      history(`/pdfViewer/${product.id}/${htmlToPlainText(product.title)}`);
    } else {
      // Show error alert if code is invalid
      alert("Invalid code. Please enter a valid 4-digit code.");
    }
  };

  const handleSizeCheckboxChange = (itemname) => {
    setSize(
      size.map((item) => ({
        ...item,
        selected: item.name === itemname, // Set selected to true for the clicked item, false for others
      }))
    );
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Check if product or product.description is undefined or null
  if (!product || !product.description) {
    return null; // or render an appropriate message or placeholder
  }
  const handleQuantityChange = (quantity, newPrice) => {
    const warehouse_quantity =
      savedLang !== "ils"
        ? product.warehouse_quantity
        : product.warehouse_quantity_is;

    // Check if warehouse quantity is greater than 0
    if (warehouse_quantity > 0 && warehouse_quantity >= quantity) {
      // Update the item quantity in the list
      setQuantity(quantity);
      setPrice(newPrice.toFixed(2));
    } else {
      // Alert when the quantity is not available
      alert(
        `${t("book")} ${htmlToPlainText(product.title)} ${t("not_enough")}`
      );
    }
  };
  const GotoCollection = () => {
    setAlertItabOpen(false);
    window.history.pushState(null, "", `/Collections`);
    window.location.reload();
  };
  const GotoCheckout = () => {
    setAlertItabOpen(false);
    window.history.pushState(null, "", `/Checkout`);
    window.location.reload();
  };
  // Conditional rendering of BookQuantityPicker based on items length
  const renderBookQuantityPicker = () => {
    if (items.length > 0) {
      return null;
    }
    return (
      <>
        <div className="flex items-center">
          <span className="text-lg font-bold mt-2 mr-1">
            {t("quantity_available")} :
          </span>
          <span className="text-lg mt-2">
            {savedLang != "ils"
              ? product.warehouse_quantity
              : product.warehouse_quantity_is}
          </span>
        </div>
        <br></br>
        <div className="mb-2">
          <span className="text-lg font-bold mt-2  mr-2">
            {t("choose_quantity")}:
          </span>
          <BookQuantityPicker
            t={t}
            savedLang={savedLang}
            product={product}
            onChange={handleQuantityChange}
            cart_quantity={cart_quantity}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <div className="container mx-auto px-4 py-8 lg:flex w-full">
        {/* <ToastContainer /> */}
        {isAlerttabOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-[199]">
            {/* Background overlay */}
            <div className="absolute inset-0 bg-gray-900 opacity-50"></div>

            {/* Modal dialog */}
            <div className="relative w-full sm:w-1/2 md:w-1/3 bg-white rounded-lg shadow-2xl overflow-hidden">
              {/* Content */}
              <div dir="rtl" className="p-6">
                <div className="flex flex-col items-center justify-center space-y-4">
                  {/* Icon */}
                  <BiCart
                    style={{
                      height: "150px",
                      width: "150px",
                      color: "#9c1131",
                    }}
                    className="m-auto"
                  />
                  {/* Success message */}
                  <p className="text-xl font-semibold text-gray-800">
                    {t('cart_successful')}
                  </p>

                  {/* Buttons */}
                  <div className="flex space-x-4">
                    <button
                      onClick={GotoCollection}
                      type="button"
                      className="ml-2 mr-2   bg-[#6D3815] w-full hover:bg-[#fde5c3] hover:text-[#000] text-white   font-medium rounded-lg text-m px-6 py-3 transition-all duration-300 shadow-lg shadow-gray-500/50"
                    >
                     {t('continue_browsing')}
                    </button>

                    <button
                      onClick={GotoCheckout}
                      type="button"
                      className="ml-2 mr-2   bg-[#9c1131] w-full hover:bg-[#fde5c3] hover:text-[#000] text-white   font-medium rounded-lg text-m px-6 py-3 transition-all duration-300 shadow-lg shadow-gray-500/50"
                    >
                      {t('go_checkout')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="pr-4 product-pic">
          {product.images ? (
            <Swiper
              modules={[Navigation]}
              navigation={true}
              className="mySwiper"
              onBeforeInit={(swiper) => {
                swiperRefswe.current = swiper;
              }}
            >
              {product.images.map((image, imageIndex) => (
                <SwiperSlide key={imageIndex}>
                  <img
                    src={image}
                    className="service-img"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "24px",
                    }}
                    alt={`Image ${imageIndex + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p>No images available</p>
          )}
        </div>
        <div className="product-info">
          <div className="flex items-center p-4">
            <h2 class="text-2xl w-[70%] font-bold text-black mb-2">
              <Markup content={product.title} />
            </h2>

            <button
              className={`bg-${
                isFavorite ? "green-500" : "[#fde5c3]"
              }  hover:bg-${
                isFavorite ? "[#fde5c3]" : "green-600"
              } text-black px-4 py-2 rounded-lg flex w-[30%]  items-center`}
              onClick={handleToggleFavorite}
            >
              {isFavorite ? (
                <FaHeartBroken className="mr-2" />
              ) : (
                <FaHeart className="mr-2" />
              )}
              {isFavorite ? <>{t("remove_fav")}</> : <>{t("add_fav")}</>}
            </button>
          </div>

          <div className=" justify-between mt-0 ml-0 items-center p-4 ">
            <div className="flex items-center">
              <span className="text-lg font-bold mr-2">
                {t("description")}:
              </span>
            </div>
            <div className="flex flex-wrap">
              {showFullDescription ? (
                <p>
                  <Markup content={product.description} />
                  {/* {product.description}{" "} */}
                  {product.description.length > 200 && (
                    <button
                      className="bg-gray-200 text-[12px] p-1"
                      onClick={toggleDescription}
                    >
                      {showFullDescription ? t('show_less') : t('read_more')}
                    </button>
                  )}
                </p>
              ) : (
                <p>
                  {product.description.length > 200 ? (
                    <Markup
                      content={product.description.slice(0, 200) + "..."}
                    />
                  ) : (
                    <Markup content={product.description} />
                  )}
                  {product.description.length > 200 && (
                    <button
                      className="bg-gray-200 text-[12px] p-1"
                      onClick={toggleDescription}
                    >
                      {showFullDescription ?  t('show_less') : t('read_more')}
                    </button>
                  )}
                </p>
              )}
            </div>
            <div className="flex items-center">
              <span className="text-m font-bold mt-2 mr-1">{t("SKU")} :</span>
              <span className="text-m mt-2 text-gray-500">{product.asin}</span>
            </div>

            <p>
              <span className="text-lg font-bold mr-2">
                {t("arthor_name")}:
              </span>
              {/* <span className="text-lg font-bold mr-0"> */}
              <Markup content={product.auther} />
              {/* </span> */}
            </p>
            <div className="flex items-center">
              <span className="text-lg font-bold mr-2">{t("material")}:</span>
            </div>
            <div className="flex flex-wrap">
              {materials.map((material, index) => (
                <>
                  <span>{material.name}</span>
                </>
              ))}
            </div>

            <div className="flex items-center">
              <span className="text-lg font-bold mt-2 mr-2">{t("Size")}:</span>
            </div>
            <div className="flex flex-wrap">
              {size.map((material) => (
                <>
                  <span className="ml-2">{material.name}</span>
                </>
              ))}
            </div>

            {renderBookQuantityPicker()}

            {modalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="bg-white p-4 rounded-lg shadow-lg w-3/4">
                  <div className="flex justify-end">
                    <button
                      onClick={closeModal}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <AiOutlineClose size={24} />
                    </button>
                  </div>
                  <div className="mt-4">
                    <img
                      src="https://via.placeholder.com/400"
                      alt="Modal Content"
                      className="w-full h-[500px]"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <p className="text-gray-700 mb-4">{product.des}</p>
          {items.length !== 0 ? (
            <>
              <h2 className="text-lg font-bold m-2">{t("volumes")}</h2>
              <div className="overflow-x-auto mb-4">
                <table className="min-w-full bg-white border-collapse">
                  <thead>
                    <tr className="border-b">
                      <th className="text-center py-2 px-4">{t("volume")}</th>
                      <th className="text-center py-2 px-4">{t("SKU")}</th>
                      <th className="text-center py-2 px-4">{t("price")}</th>
                      <th className="text-center py-2 px-4">
                        {t("quantity_available")}
                      </th>

                      <th className="text-center py-2 px-4">
                        {t("choose_quantity")}
                      </th>
                      <th className="text-center py-2 px-4">{t("add")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item) => (
                      <tr key={item.id} className="border-b">
                        <td className="text-left py-2 px-4">{item.title}</td>
                        <td className="text-left py-2 px-4">{item.asin??"None"}</td>

                        <td className="text-left py-2 px-4">
                          {savedLang !== "ils" ? item.price : item.price_is}
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                          className="py-2 px-4"
                        >
                          {savedLang !== "ils"
                            ? item.warehouse_quantity
                            : item.warehouse_quantity_is}
                        </td>
                        <td className="text-center py-2 px-4">
                          <QuantityPicker
                            t={t}
                            savedLang={savedLang}
                            item={item}
                            onChange={handleitemQuantityChange}
                            product={product}
                            cart_quantity={cart_quantity}
                          />
                        </td>
                        <td className="text-center py-2 px-4">
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox  h-5 w-5 text-indigo-600"
                              checked={item.selected}
                              onChange={() =>
                                item.must_buy != "1"
                                  ? handleCheckboxChange(item.id)
                                  : null
                              }
                            />
                            {/* You can add label text here if needed */}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <h2 className=""> </h2>
          )}
          <div className=" items-center space-x-4">
            <div className="md:flex sm:block">
              <div className="md:w-[49%] m-auto sm:w-full">
                <button
                  onClick={()=>{
                    history(`/pdfViewer/${product.id}/${htmlToPlainText(product.title)}`);
                  }}
                  className="bg-[#6D3815] hover:bg-[#fde5c3] w-full text-white hover:text-black px-4 py-2 rounded-lg flex items-center"
                >
                  <FaBookReader className="mr-2" />
                  {t("view_book")}
                </button>
                {/* </Link> */}
                {passwordmodalOpen && (
                  <div className="fixed inset-0  z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white w-[40%] p-8 rounded-lg shadow-lg w-3/4">
                      <div className="flex justify-end">
                        <button
                          onClick={closepassModal}
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <AiOutlineClose size={24} />
                        </button>
                      </div>
                      <div className="mt-4 p-4">
                        <p className="text-xl text-center">
                          Enter password to view book
                        </p>
                        <div
                          dir="ltr"
                          className="flex justify-center mt-2 space-x-2"
                        >
                          <input
                            type="number"
                            maxLength="1"
                            value={code[0]}
                            onChange={(e) => handleChange(0, e.target.value)}
                            className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
                            placeholder="0"
                          />
                          <input
                            type="number"
                            maxLength="1"
                            value={code[1]}
                            onChange={(e) => handleChange(1, e.target.value)}
                            className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
                            placeholder="0"
                          />
                          <input
                            type="number"
                            maxLength="1"
                            value={code[2]}
                            onChange={(e) => handleChange(2, e.target.value)}
                            className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
                            placeholder="0"
                          />
                          <input
                            type="number"
                            maxLength="1"
                            value={code[3]}
                            onChange={(e) => handleChange(3, e.target.value)}
                            className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
                            placeholder="0"
                          />
                        </div>

                        <p
                          id="helper-text-explanation"
                          class="mt-2 text-sm text-gray-500 text-center"
                        >
                          Please introduce the 4 digit code
                        </p>
                        <div className="w-full">
                          <button
                            type="button"
                            className="text-white w-[100%] bg-gradient-to-r from-cyan-400 via-cyan-500 m-auto to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                            onClick={() => {
                              history(`/pdfViewer/${product.id}/${htmlToPlainText(product.title)}`);
                              // checkCode();
                            }}
                          >
                            View Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {showRentForm && (
              <div className="container mx-auto px-4 py-4 pt-0">
                <h2>Book viewed for:</h2>
                <form className="flex items-center space-x-4">
                  <div>
                    <label
                      htmlFor="duration"
                      className="text-gray-700 font-bold"
                    >
                      Duration: One Month
                    </label>
                    
                  </div>
                  {selectedDuration && (
                    <div>
                      <label
                        htmlFor={selectedDuration}
                        className="text-gray-700 font-bold"
                      >
                        {selectedDuration.charAt(0).toUpperCase() +
                          selectedDuration.slice(1)}{" "}
                        :
                      </label>
                      <select
                        id={selectedDuration}
                        className="border px-2 py-1 rounded-full bg-blue-200 text-blue-800"
                        defaultValue="1"
                      >
                        {[...Array(selectedDuration === "days" ? 6 : selectedDuration === "weeks" ? 4 : 12)].map(
                          (_, index) => (
                            <option key={index + 1} value={index + 1}>
                              {index + 1}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                  <Link to=
                  {`/pdfViewer/${product.id}/${product.title}`}>
                    <button
                      className="bg-[#fde5c3] hover:bg-[#6D3815] text-white px-4 py-2 rounded-lg"
                      type="submit"
                    >
                      {t('view_book')}
                    </button>
                  </Link>
                </form>
              </div>
              
            )} */}
              </div>
              <div className="md:w-[49%] sm:w-full">
  <button
    className="bg-[#6D3815] w-full hover:bg-[#fde5c3] hover:text-[#000] text-white px-4 py-2 rounded-lg flex items-center"
    onClick={(e) => purchase(e, product)}
    style={{ marginLeft: "2px" }}
  >
    <FaShoppingCart className="mr-2" />
    {t("buy_book")} 
    <span className="text-xl mx-1">{symbol}</span> {/* Adds space on both sides */}
    <span >{price}</span> {/* Adds space only to the left */}
  </button>
</div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const QuantityPicker = ({
  t,
  savedLang,
  item,
  onChange,
  product,
  cart_quantity,
}) => {
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange(item.id, newQuantity);
    }
  };

  const increaseQuantity = () => {
    if (item.selected) {
      // const item = items.find(item => item.id === itemId);

      const warehouse_quantity =
        savedLang !== "ils"
          ? item.warehouse_quantity
          : item.warehouse_quantity_is;
      const c_quantity =
        cart_quantity.find(
          (itm) => itm.book_id === `${product.id}-vol-${item.id}`
        )?.quantity ?? 0;
      // Check if warehouse quantity is greater than 0
      if (
        warehouse_quantity > 0 &&
        warehouse_quantity > quantity + c_quantity
      ) {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        onChange(item.id, newQuantity);
      } else {
        alert(`${t("book")} ${item.title} ${t("not_enough")}`);
      }
    }
  };

  return (
    <div className="flex items-center">
     {!item.must_buy && (
  <button
    className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-l focus:outline-none"
    onClick={decreaseQuantity}
  >
    -
  </button>
)}
      
      <span className="px-4">{quantity}</span>
      {!item.must_buy && (
  <button
  className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-r focus:outline-none"
  onClick={increaseQuantity}
>
  +
</button>
)}
     
    </div>
  );
};
const BookQuantityPicker = ({
  t,
  savedLang,
  product,
  onChange,
  cart_quantity,
}) => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange(newQuantity, newQuantity * parseFloat(product.price));
    }
  };

  const increaseQuantity = () => {
    const warehouse_quantity =
      savedLang !== "ils"
        ? product.warehouse_quantity
        : product.warehouse_quantity_is;

    const c_quantity =
      cart_quantity.find((item) => item.book_id === `${product.id}`)
        ?.quantity ?? 0;
    // Check if warehouse quantity is greater than 0
    if (warehouse_quantity > 0 && warehouse_quantity > quantity + c_quantity) {
      // Update the item quantity in the list
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onChange(newQuantity, newQuantity * parseFloat(product.price));
    } else {
      // Alert when the quantity is not available
      alert(
        `${t("book")} ${htmlToPlainText(product.title)} ${t("not_enough")}`
      );
    }
  };

  return (
    <div className="flex items-center">
      <button
        className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-l focus:outline-none"
        onClick={decreaseQuantity}
      >
        -
      </button>
      <span className="px-4">{quantity}</span>
      <button
        className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-r focus:outline-none"
        onClick={increaseQuantity}
      >
        +
      </button>
    </div>
  );
};
export default Product;
