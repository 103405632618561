import React, { useState, useEffect, useContext } from "react";
import "./wallet.css";  // Custom CSS for styling
import BASE_URL from "../constant";
import axios from "axios";
import { t } from "i18next";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

// WalletPage Component
const WalletPage = () => {
  const { getUserData } = useContext(AuthContext);
  const [balance, setBalance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [predefinedAmount, setPredefinedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");

  const predefinedAmounts = [10, 20, 50, 100,150, 200,250]; // predefined amounts for top-up
  const navigate = useNavigate();

  // Function to handle predefined amount selection
  const handleAmountSelect = (amount) => {
    setPredefinedAmount(amount);
    setCustomAmount(amount);
    // setCustomAmount(""); // Clear the custom amount when predefined is selected
  };

  // Function to handle custom amount input change
  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setPredefinedAmount(null); // Clear predefined amount when typing custom
  };

//   // Handle form submission (top-up action)
//   const handleSubmit = () => {
//     const amountToTopUp = predefinedAmount || parseFloat(customAmount);

//     if (amountToTopUp <= 0 || isNaN(amountToTopUp)) {
//       setError("Please enter a valid amount.");
//       return;
//     }

//     // Proceed with top-up logic (e.g., make an API request)
//     console.log(`Topping up wallet with amount: ${amountToTopUp}`);
//     setError(""); // Reset error message if successful
//     setIsModalOpen(false); // Close the modal after successful submission
//   };
  const handleSubmit = () => {
    const amountToTopUp = predefinedAmount || parseFloat(customAmount);

    if (amountToTopUp <= 0 || isNaN(amountToTopUp)) {
      setError("Please enter a valid amount.");
      return;
    }
    navigate('/pages/Stripe',{state:{
        product:{title:"wallet-top-up",transaction_type:"credit",transaction_msg:"top-up purchased",},
        amount:amountToTopUp,
        type:"wallet-top-up",
        page:"wallet"
       }} );
       setError(""); // Reset error message if successful
           setIsModalOpen(false);
    // const userdata = getUserData();

    // // Proceed with the API request to add the amount
    // axios
    //   .post(`${BASE_URL}/api_wallet.php`, {
    //     user_id: userdata.id, // User ID
    //     amount: amountToTopUp, // Amount to top-up
    //     transaction_type:"credit"
    //   })
    //   .then((response) => {
    //     if (response.data) {
    //       // Assuming success response contains a success flag
    //       setBalance(response.data.new_balance); // Update balance with the new balance
    //       setError(""); // Reset error message
    //       setIsModalOpen(false); // Close the modal after successful submission
    //     } else {
    //       setError(response.data.message || "Error topping up wallet");
    //     }
    //   })
    //   .catch((error) => {
    //     setError("Error topping up wallet");
    //   });
  };
  // Fetch wallet balance and transactions on mount
  useEffect(() => {
    const userdata = getUserData();

    // Fetch wallet balance using Axios
    axios
      .get(`${BASE_URL}/api_wallet.php?user_id=${userdata.id}`, {
        params: { user_id: userdata.id } // Send user ID as a query parameter
      })
      .then((response) => {
        setBalance(response.data.balance); // Assuming the response contains a 'balance' field
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching wallet balance");
        setLoading(false);
      });
  }, [getUserData]);

  return (
    <div className="wallet-container">
      {/* Modal for top-up */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <div>
              <h3 className="wallet-header">{t('topup') } Wallet</h3>


              <div>
                <label className="block text-sm font-semibold">Enter Amount</label>
                <input
                  type="number"
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                  className="w-full mt-2 p-2 border rounded-lg"
                  placeholder="Enter amount"
                />
              </div>
              <div className="overflow-x-auto whitespace-nowrap mt-4">
              <label className="block text-sm font-semibold">Select an Amount</label>
  {predefinedAmounts.map((amount, index) => (
    <button
      key={index}
      onClick={() => handleAmountSelect(amount)}
      className={`inline-block px-4 py-2 rounded-lg border mr-2 ${
        predefinedAmount === amount
          ? "bg-red-900 text-white"
          : "bg-gray-100 text-gray-700"
      }`}
    >
      ${amount}
    </button>
  ))}
</div>
              {error && <div className="text-red-500 text-sm">{error}</div>}
              <div className="mt-4">
                <button
                  onClick={handleSubmit}
                  className="w-full py-2 px-4 bg-red-800 text-white rounded-lg"
                >
                  {t('topup') } 
                </button>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-2 w-full py-2 px-4 text-gray-500 border rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Wallet Balance Section */}
      <div className="wallet-card">
        <h2 className="wallet-header">{t("your_wallet")}</h2>
        <div className="balance">
          {loading ? (
            <span className="balance-text"> {t('loading')}</span>
          ) : error ? (
            <span className="balance-text error">{error}</span>
          ) : (
            <span className="balance-text">${balance}</span>
          )}
        </div>
        <button
          className="transaction-btn"
          onClick={() => setIsModalOpen(true)} // Open the modal when the button is clicked
        >
          {t("topup")}
        </button>
      </div>

      {/* Transaction History Section (Optional) */}
      {/* Uncomment and implement transaction fetching logic as needed */}
      {/* <div className="transaction-history">
        <h3 className="history-header">Transaction History</h3>
        <div className="history-list">
          {transactions.map((transaction, index) => (
            <div className="transaction" key={index}>
              <span className="transaction-date">{transaction.date}</span>
              <span className={`transaction-amount ${transaction.type}`}>
                {transaction.type === "credit" ? "+" : "-"}${transaction.amount.toFixed(2)}
              </span>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default WalletPage;
